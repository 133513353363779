import React from "react"
import { graphql, useStaticQuery } from "gatsby"
// components
import Layout from "../components/Layouts/layout"
import SEO from "../components/seo"
// components
import {
    FirstScreen,
    Mission,
    Team,
    SocialResponsibility,
} from '../components/AboutCompany'
// global
import { 
    FormOrder,
    Breadcrumbs
} from '../components/Global'
// content
import YAMLGlobal from "../content/ru/global.yaml"
import YAMLAbout from "../content/ru/about.yaml"

const AboutCompanyPage = ({location}) => {
  // Тут собрана вся необходимая инфа - передаешь пропсами вниз компонентам. Обращаться можно (data.mainInfo....)
    const data = useStaticQuery(graphql`
        query {
            seo: directusAboutSeo {
                title
                description
            }
            mainInfo: directusAboutMain {
                about_image {
                    localFile {
                        childImageSharp {
                            fluid(maxWidth: 1000) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                about_title
                about_description
                title
                mission_image {
                    localFile {
                        childImageSharp {
                            fluid(maxWidth: 520) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                mission_description
                goal_image {
                    localFile {
                        childImageSharp {
                            fluid(maxWidth: 520) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                goal_description
            }
            team: allDirectusTeam(sort: {fields: sort}) {
                edges {
                    node {
                        id
                        name
                        label
                        description
                        image {
                            localFile {
                                childImageSharp {
                                    fluid(maxWidth: 300) {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                    }
                }
            }
            blog: allDirectusBlog(filter: {is_social: {eq: true}}, limit: 6) { #Лимит выбери как считаешь нужным
                edges {
                    node {
                        id
                        slug
                        title
                        image {
                            localFile {
                                childImageSharp {
                                    fluid(maxWidth: 1000) {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `)

    const list = [
        {
            link: "/",
            title: YAMLGlobal.breadCrumbs.main
        },
        {
            link: '/about-company',
            title: YAMLGlobal.breadCrumbs.about
        }
    ]
    
    const aboutLocal = {
        langBase: "",
        YAMLGlobal,
        YAMLContent: YAMLAbout
    }
    
    return(
        <Layout local={aboutLocal} location={location}>
            <SEO title={data.seo.title} description={data.seo.description} />
            <Breadcrumbs list={list} />
            <FirstScreen
                image={data.mainInfo.about_image.localFile && data.mainInfo.about_image.localFile?.childImageSharp.fluid }
                title={data.mainInfo.about_title}
                description={data.mainInfo.about_description}
            />
            <Mission
              title={data.mainInfo.title}
              mission={{
                image: data.mainInfo.mission_image.localFile && data.mainInfo.mission_image.localFile?.childImageSharp.fluid,
                description: data.mainInfo.mission_description
              }}
              goal={{
                image: data.mainInfo.goal_image.localFile?.childImageSharp.fluid,
                description: data.mainInfo.goal_description
              }}
            />
            <Team teammates={data.team.edges} />
            <SocialResponsibility articles={data.blog.edges} />
            <FormOrder/>
        </Layout>
    )
}

export default AboutCompanyPage;